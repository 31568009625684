import React, { Component } from "react"
import {
  FaBriefcaseMedical,
  FaGraduationCap,
  FaRegComments,
  FaRegFileAlt,
  FaUserFriends,
  FaQuestion,
} from "react-icons/fa"
import { Link } from "gatsby"

const ServiceList = [
  {
    icon: <FaBriefcaseMedical />,
    title: "Diseño y dirección de reuniones de expertos",
    description: "Amplia experiencia en diferentes especialidades",
  },
  {
    icon: <FaGraduationCap />,
    title: "Diseño y desarrollo de iniciativas de formación médica continuada",
    description: "Utilizando herramientas tecnológicas de última generación",
  },
  {
    icon: <FaRegComments />,
    title: "Diseño y dirección de Advisory Boards",
    description:
      "<strong>Presenciales o a distancia</strong><br/>Que faciliten la construcción o la evaluación de los planes del departamento de marketing",
  },
  {
    icon: <FaRegFileAlt />,
    title: "Diseño de anteproyectos",
    description: "Necesarios para el desarrollo de la estrategia",
  },
  {
    icon: <FaUserFriends />,
    title: "Diseño de estrategias de trabajo conjunto entre equipos",
    description: "Construcción de planes estratégicos y de planes de acción",
  },
  {
    icon: <FaQuestion />,
    title: "Saber más...",
    description:
      "Haz clic para conocer mejor estos y todos los otros servicios que GOT IT puede ofrecer",
  },
]

class Services extends Component {
  render() {
    let title = "Qué hacemos",
      description =
        "GOT IT oferece una gran variedad de servicios para ayudar a las empresas del ámbito de la salud"
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">{title}</h2>
              <p>{description}</p>
              <div className="service-btn">
                <Link className="btn-transparent rn-btn-dark" to="/services">
                  <span className="text">Ver todos los servicios</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <Link to="/services">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p dangerouslySetInnerHTML={{__html: val.description}} />
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default Services
