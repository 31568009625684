import React, { Component } from "react"
import Highlights from "./hightlights"

class SliderHome extends Component {
  render() {
    return (
      <div className="slider-activation">
        <div
          className="slide slide-home slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_home"
        >
          <div className="container position-relative">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h2 className="title theme-gradient">
                    EXPERIENCIA Y<br /> CONOCIMIENTO
                  </h2>
                  <h3 className="">
                    para llevar a cabo todos tus proyectos
                    <br /> en el ámbito de la salud
                  </h3>
                </div>
              </div>
            </div>
            <div className="service-wrapper service-white">
              <Highlights />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default SliderHome
