import React, { Component } from "react"
import { FiMaximize2, FiActivity, FiLoader } from "react-icons/fi"

const ServiceList = [
  {
    icon: <FiMaximize2 />,
    title: "Flexibilidad",
    description:
      "Estructura lean y diferentes colaboradores nos permiten adaptar los proyectos de forma rápida y efectiva",
  },
  {
    icon: <FiActivity />,
    title: "Agilidad",
    description:
      "Compromiso de agilidad y fluidez de comunicación en todas las etapas del proyecto",
  },
  {
    icon: <FiLoader />,
    title: "Know how",
    description:
      "Más de 10 años de experiencia en el sector y millares de sesiones y proyectos realizados",
  },
]

class Highlights extends Component {
  render() {
    return (
      <>
        <div className="row">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }
}
export default Highlights
