import React from "react"
import Layout from "../components/layout"
import SliderHome from "../components/sliderHome"
import Services from "../components/services"
import Footer from "../components/footer"

const IndexPage = () => (
  <Layout title="Especialistas en advisory boards y reuniones de expertos" headerTransparent={true}>
    <div className="slider-wrapper">
      <SliderHome />
    </div>

    <div className="service-area ptb--80  bg_image bg_services">
      <div className="container">
        <Services />
      </div>
    </div>

    <Footer />
  </Layout>
)

export default IndexPage
